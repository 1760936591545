<template>
  <div></div>
</template>

<script>
//let shorturl= await fetch(url)
//let fullurl=null
//function {
//}
//this.$router.push(fullurl)
//
export default {
  name: `redirect`
  //components:{shorturl,fullurl}
}
</script>

<style scoped>

</style>